<style scoped lang="scss">
.edenred-button {
  border: 2px solid #1d204c;
  border-radius: 10px;
  padding: 4px;
  min-width: 200px;
  min-height: 48px;
  display: inline-block;
  text-align: center;
  width: auto;
  img {
    height: 40px;
  }
}

.white-background {
  background-color: $white !important;
}

.payment-type-spendingcard {
  .descr {
    font-size: 16px;
  }
}
.logout-panel {
  max-width: 500px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.69);
  [class^="col-"],
  [class*=" col-"] {
    padding: 10px;
  }
  .img {
    .logo-heading-image {
      text-align: right;
      width: 100px;
    }
  }
}
.logout-button {
  text-decoration: underline;
  color: $primary;
  font-weight: bold;
  font-size: 12px;
}
.authenticated {
  width: 100%;
}
.max-payable {
  margin-top: 16px;
  margin-bottom: 16px;
}
.ticket-data-wrapper {
  max-width: 500px;
  margin: auto;
  font-weight: 800;
  [class^="col-"],
  [class*=" col-"] {
    padding: 8px;
    text-align: center;
  }
  .row {
    border-bottom: 1px solid -var(grey-lighten2);
    &:last-child {
      border-bottom-color: transparent;
    }
  }
  .header {
    color: -var(grey-lighten1);
    font-weight: bold;
  }
  .footer-ticket {
    background: transparent;
    color: -var(grey-lighten1);
    font-weight: bold;
  }
  .qty-wrapper {
    border: 1px solid -var(grey-lighten2);
    border-radius: 4px;
    display: inline-flex;
    .increment-button {
      background-color: transparent;
      border: none;
      border-left: 1px solid -var(grey-lighten2);
      padding: 4px 12px;
    }
    .qty {
      min-width: 80px;
      padding: 4px 12px;
    }
    .decrement-button {
      background-color: transparent;
      border: none;
      border-right: 1px solid -var(grey-lighten2);
      padding: 4px 12px;
    }
  }
  .v-dialog {
    overflow: hidden;
  }
  .payment-disabled {
    opacity: 0.8;
    pointer-events: none;
  }
}
/*
.v-text-field--outlined {
    top: 14px !important;
}
*/
.v-list-item__content {
  padding: 0px;
}
</style>

<template>
  <v-container
    class="payment-type payment-type-nexi payment-type-spendingcard px-0 px-sm-auto"
  >
    <span class="descr" v-html="paymentType.descr"></span>

    <ResponseMessage :response="response" />

    <v-row class="d-flex justify-center mt-5">
      <div
        class="authenticated"
        v-if="
          giftCardData.logged || giftCardData.giftCertificateConfirmed == true
        "
      >
        <div class="max-payable justify-start">
          Puoi pagare fino a
          <strong>{{ $n(giftCardData.maxAmount, "currency") }}</strong> della
          tua spesa con
          {{
            $t("orders.giftCardType." + giftCardData.paymentType.paymentTypeId)
          }}
        </div>

        <div class="payment-auth-list">
          <div v-if="giftCards.length">
            <v-list>
              <strong>Le tue carte</strong>
              <!--
                <v-list-item-group v-model="selectedRow" mandatory>

              -->

              <v-list-item-group mandatory>
                <v-list-item
                  v-for="(giftCard, index) in giftCards"
                  :key="giftCard.cardId"
                  style="display: flex;    justify-content: flex-start; align-items: center;"
                >
                  <v-list-item-content
                    style="flex-wrap:nowrap;min-width: 200px;"
                  >
                    <v-checkbox
                      v-model="giftCard.selected"
                      @change="update(giftCard)"
                      :disabled="giftCard.disabled"
                      :key="index"
                    />

                    <div>
                      <v-list-item-title>
                        {{ giftCard.cardNumberMask | ccard }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Attivazione:
                        {{ $dayjs(giftCard.expiredDate).format("YYYY-MM-DD") }}
                        <br />
                        Scadenza:
                        {{ $dayjs(giftCard.expiredDate).format("YYYY-MM-DD") }}
                        <br />
                        Stato: {{ giftCard.status }}
                      </v-list-item-subtitle>
                    </div>
                  </v-list-item-content>

                  <v-list-item-content style=" min-width: 120px;">
                    <v-list-item-title>
                      Valore: {{ $n(giftCard.value, "currency") }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="  color: rgba(0, 0, 0, 0.8);  font-weight: bold;"
                    >
                      Saldo: {{ $n(giftCard.availableAmount, "currency") }}
                      <br />
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-title>
                      <br />

                      <v-text-field
                        v-if="giftCard.requiredPin"
                        style="width:84px;"
                        color="primary"
                        v-model="giftCard.pin"
                        :error-messages="errors"
                        type="password"
                        label="PIN"
                        autocomplete="off"
                        class="mb-2"
                        :disabled="giftCard.disabled"
                        :required="giftCard.requiredPin"
                        dense
                        outlined
                        clearable
                        mask="*"
                        @change="updatePin(giftCard)"
                      >
                      </v-text-field>
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-title>
                      Totale: {{ $n(giftCard.currentAmount, "currency") }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      :disabled="giftCard.selected"
                      small
                      icon
                      @click="removePaymentAuth(giftCard)"
                      class="remove-card"
                      aria-label="Rimuovi questa carta"
                      title="Rimuovi questa carta"
                    >
                      <v-icon>$delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div class="ticket-data-wrapper">
            <div class="row footer-ticket">
              <div class="col-10"></div>
              <div class="col-2">Totale: {{ $n(grossTotal, "currency") }}</div>
            </div>
          </div>
        </div>

        <br />
        <div class="logout-panel row" v-if="giftCardData.logged">
          <div class="col-3 img">
            <v-img
              class="logo-heading-image"
              :src="
                '/img_layout/gift_card/' + paymentType.paymentTypeId + '.svg'
              "
              :alt="'Logo' + paymentType.paymentName"
            />
          </div>
          <div class="col-9">
            <strong
              >Aggiungi nuova Carta
              {{ giftCardData.paymentType.paymentTypeId == 26 ? "CAMì" : "" }}
            </strong>
          </div>
          <div class="col-12 d-flex" style=" height: 60px;">
            <div class="col-7">
              <v-text-field
                color="primary"
                v-model="newCard.cardNumber"
                :error-messages="errors"
                type="text"
                label="Numero Carta"
                autocomplete="off"
                class="mb-2"
                required
                dense
                outlined
                clearable
              >
              </v-text-field>
            </div>
            <div class="col-5">
              <v-text-field
                color="primary"
                v-model="newCard.pin"
                :error-messages="errors"
                :append-icon="showPassword ? '$eye' : '$eyeOff'"
                :type="showPassword ? 'text' : 'password'"
                autocomplete="off"
                label="PIN"
                class="mb-2"
                required
                dense
                outlined
                clearable
                mask="*"
                @input="editPin"
                @keypress="editPin"
              >
              </v-text-field>
            </div>
          </div>
          <!--
            <div  class="col-12" style=" height: 60px;"  >
              <v-text-field
                color="primary"
                v-model="newCard.barcode"
                :error-messages="errors"
                type="text"
                label="Codice a Barre"
                autocomplete="off"
                class="col-12"
                required
                dense
                outlined
                clearable
                >
              </v-text-field>
            </div>
          
          -->
          <div class="col-12 " style=" margin-top: 10px;">
            <div class="d-flex flex-column remember-password">
              <v-btn
                :loading="loading_newcard"
                type="submit"
                color="#9e0005"
                large
                block
                depressed
                class="white--text"
                @click="rememberNewCard"
              >
                {{ $t("paymentTypeList.rememberNewCard") }}
              </v-btn>
              <ResponseMessage :response="response" class="mt-3" />
            </div>
          </div>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import ResponseMessage from "@/components/ResponseMessage";
import GiftCardService from "~/service/spendingCard/giftCardService";

import gateway from "~/mixins/gateway";
import { mapActions, mapGetters } from "vuex";
import { requiredValue } from "~/validator/validationRules";

export default {
  name: "PaymentTypeGiftCard",
  mixins: [gateway],
  data() {
    return {
      loading: false,
      username: "",
      newCard: { cardNumber: null, barcode: null, pin: null },
      showPassword: null,
      password: "",
      error: null,
      selectedPaymentAuthId: null,
      giftCardData: {},
      response: {},
      loading_newcard: false,
      dialog: false,
      valid: true,
      lazy: true,
      passwordRules: [requiredValue("Digitare la password")],
      errors: [],
      invalid: null
    };
  },
  components: { ResponseMessage },
  computed: {
    giftCards() {
      return this.giftCardData &&
        this.giftCardData.data &&
        this.giftCardData.data.giftCards
        ? this.giftCardData.data.giftCards
        : [];
    },
    grossTotal() {
      return this.giftCardData.data ? this.giftCardData.data.grossTotal : null;
    }
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),

    goBack() {
      this.$router.push("/checkout");
    },
    async getDetail() {
      this.loading = true;
      if (this.paymentType.paymentTypeId == 26) {
        this.giftCardData = await GiftCardService.getCami(
          this.paymentType.paymentTypeId
        );
        console.log("getDetail");
        console.log(this.giftCardData);

        if (
          this.giftCardData &&
          !this.giftCardData.logged &&
          this.giftCardData.extraParams &&
          this.giftCardData.extraParams.AUTOCALL
        ) {
          //console.log("AUTOCALL : " +this.giftCardData.redirectUrl);
          this.giftCardData = await GiftCardService.prepareGiftCards(
            this.paymentType.paymentTypeId
          );
          // payment(this.giftCardData.redirectUrl, null, "get");
        }
      }
      this.loading = false;
      this.getCart();
    },
    editPin(event) {
      console.log(event);

      if (this.newCard.pin == null) {
        global.EventBus.$emit("error", {
          message: global.EventBus.$t("errors.giftCard.emptyPin")
        });
        return;
      }
      /*
      if(!this.giftCard.selected){
        this.giftCard.currentAmount = 0.0;
      }
      else{
        if(this.giftCard.selected && this.giftCard.pin == null ){
          global.EventBus.$emit(  "error", {
                message: global.EventBus.$t(  "errors.giftCard.emptyPin"  )
          });
          return;
        }  
      }
      */
    },
    async updatePin(giftCard) {
      console.log("updatePin");

      if (giftCard.pin != null && giftCard.requiredPin) {
        giftCard.selected = true;
        this.update(giftCard);
      }
    },
    async update(giftCard) {
      console.log("update");
      console.log(giftCard);
      if (!giftCard.selected) {
        giftCard.currentAmount = 0.0;
      } else {
        if (giftCard.selected && giftCard.pin == null && giftCard.requiredPin) {
          global.EventBus.$emit("error", {
            message: global.EventBus.$t("errors.giftCard.emptyPin")
          });

          giftCard.selected = false;
          for (var data in this.giftCardData.giftCards) {
            if (data.cardId == giftCard.cardId) {
              data.selected = false;
            }
          }
          global.EventBus.$emit("giftcard-update");
          return;
        }
      }

      if (this.giftCardData.paymentType.paymentTypeId == 26) {
        this.giftCardData = await GiftCardService.update(
          this.giftCardData.paymentType.paymentTypeId,
          this.giftCardData.data
        );
      }
      global.EventBus.$emit("giftcard-update");

      this.getCart();
    },

    async rememberNewCard() {
      var _this = this;
      let errMsgCode = null;

      if (this.newCard.cardNumber == null && this.newCard.barcode == null) {
        errMsgCode = "errors.giftCard.emptyCardNumber";
      }
      /*
      else  if(this.newCard.pin==null ){
        errMsgCode= "errors.giftCard.emptyPin";
      }
      */
      if (errMsgCode != null) {
        global.EventBus.$emit("error", {
          message: global.EventBus.$t(errMsgCode)
        });
        return;
      }

      this.loading = true;
      this.loading_newcard = true;

      if (this.giftCardData.paymentType.paymentTypeId == 26) {
        await GiftCardService.addNewGiftcard(
          this.giftCardData.paymentType.paymentTypeId,
          this.newCard
        );
      }
      this.loading_newcard = false;
      this.loading = false;

      let dataCards = await GiftCardService.list();

      if (!_this.giftCardData.data) {
        _this.giftCardData.data = {};
      }
      _this.giftCardData.data.giftCards = dataCards[0].data.giftCards;

      this.getCart();
      this.resetNewCard();
    },

    async removePaymentAuth(giftCard) {
      var _this = this;
      let errMsgCode = null;

      if (giftCard.cardId == null) {
        errMsgCode = "errors.giftCard.emptyCardNumber";
      }

      if (errMsgCode != null) {
        global.EventBus.$emit("error", {
          message: global.EventBus.$t(errMsgCode)
        });
        return;
      }

      this.loading = true;
      this.loading_newcard = true;

      if (this.giftCardData.paymentType.paymentTypeId == 26) {
        await GiftCardService.removeGiftcard(
          this.giftCardData.paymentType.paymentTypeId,
          giftCard.cardId
        );
      }
      this.loading_newcard = false;
      this.loading = false;
      let dataCards = await GiftCardService.list();

      if (!_this.giftCardData.data) {
        _this.giftCardData.data = {};
      }
      _this.giftCardData.data.giftCards = dataCards[0].data.giftCards;

      this.getCart();
    },

    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    resetNewCard() {
      this.newCard = { cardNumber: null, barcode: null, pin: null };
    }
  },
  mounted() {
    global.EventBus.$on("closeDialog", () => {
      this.dialog = false;
    });
  },
  created() {
    if (this.paymentType.logged) {
      this.giftCardData = this.paymentType;
      this.getDetail();
    } else {
      this.getDetail();
    }
  },
  watch: {
    selected(newValue, oldValue) {
      if (newValue != oldValue && newValue == true) {
        this.getDetail();
      }
    }
  }
};
</script>
